import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Image from 'reusecore/src/elements/Image';
// import Logo from "reusecore/src/elements/UI/Logo";
import Heading from 'reusecore/src/elements/Heading';
import Container from 'common/src/components/UI/Container';
import FooterArea, {
  WidgetArea,
  MenuArea,
  // Menu,
  // MenuItem,
  CopyrightText,
} from './footer.style';

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      apploadJson {
        footer {
          widgets {
            id
            icon {
              publicURL
            }
            title
            url
            text
          }
        }
      }
    }
  `);
  const { widgets } = data.apploadJson.footer;

  const date = new Date();
  const year = date.getFullYear();

  return (
    <FooterArea id="contact">
      <Container>
        <WidgetArea>
          {widgets &&
            widgets.map(item => (
              <Box className="col" key={`footer-widget--key${item.id}`}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link"
                  href={item.url}
                >
                  <div>
                    <Image src={item.icon.publicURL} alt={item.title} />
                    <Heading as="h3" content={item.title} />
                    <Text content={item.text} />
                  </div>
                </a>
              </Box>
            ))}
        </WidgetArea>
        {/* End of footer widgets area */}
        <MenuArea>
          {/* <Logo className="logo" logoSrc={logo.publicURL} title="Appload" /> */}
          {/* <Menu>
            {menu &&
              menu.map(item => (
                <MenuItem key={`footer-link${item.id}`}>
                  <a href={item.link}>{item.text}</a>
                </MenuItem>
              ))}
          </Menu> */}
          <CopyrightText>
            &copy; {year} Appload Ltd, all rights reserved.
          </CopyrightText>
        </MenuArea>
        {/* End of footer menu area */}
      </Container>
    </FooterArea>
  );
};

export default Footer;
