import styled from "styled-components";
import { themeGet } from "styled-system";

const BannerWrapper = styled.div`
  // padding-top: 100px;
  min-height: 100vh;
  background-color: ${themeGet("colors.light", "#FAFBFF")};
  position: relative;
  z-index: 1;
  text-align: center;
  display: flex;
  align-items: center;
  overflow: hidden;
  @media only screen and (min-width: 400px) {
    // min-height: 100vh;
    padding-top: 100px;
  }
  @media only screen and (max-width: 479px) {
    padding-top: 0x;
    .logo {
      height: 128px;
    }
  }
  > div.container {
    min-height: calc(100vh - 100px);
    @media only screen and (min-width: 1201px) and (max-width: 1440px) {
      min-height: calc(100vh - 100px);
    }
    @media only screen and (max-width: 480px) {
      flex-wrap: wrap;
    }
  }
`;

export const BannerContent = styled.div`
  // max-width: 425px;
  width: 100%;
  z-index: 3;
  position: relative;
  margin: 0 auto;
  // display: -webkit-box;
  // display: -webkit-flex;
  // display: flex;
  // flex-direction: column;
  // -webkit-align-items: center;
  // -webkit-box-align: center;
  // align-items: center;
  // -webkit-justify-content: center;
  // justify-content: center;
  // min-height: calc(802px - 100px);
  text-align: center;
  @media only screen and (max-width: 991px) {
    flex-shrink: 0;
    // max-width: 360px;
  }

  h1 {
    color: ${themeGet("colors.menu", "#0D233E")};
    margin-bottom: 24px;
    @media only screen and (max-width: 1366px) {
      // font-size: 32px;
      line-height: 42px;
      margin-bottom: 20px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 48px;
      line-height: 42px;
      margin-bottom: 20px;
    }
  }

  h2 {
    @media only screen and (max-width: 480px) {
      width: 85%;
      margin: 0 auto;
      font-size: 18px;
      margin-bottom: 20px;
    }
  }
`;

export const RatingInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 22px;
  color: ${themeGet("colors.menu", "#0D233E")};

  .rating {
    margin-right: 10px;
    .star {
      color: ${themeGet("colors.yellow", "#FFA740")};
    }
    .star-o {
      color: #e8e8e8;
    }
  }

  img {
    margin-left: 9px;
  }
`;

export const BannerImage = styled.div`
  flex-shrink: 0;
  align-self: flex-end;
  @media only screen and (max-width: 480px) {
    margin-top: 40px;
    img {
      max-width: 70%;
    }
  }
`;

export const ButtonGroup = styled.div`
  margin-top: 35px;

  .reusecore__button {
    text-transform: inherit;
    border-radius: 5px;
    padding-left: 16px;
    padding-right: 16px;

    &.primary {
      &:hover {
        background-color: ${themeGet("colors.primaryHover", "#3C74FF")};
      }
    }

    &.text {
      .btn-icon {
        svg {
          width: auto;
          height: 25px;
        }
      }
      &:hover {
        color: ${themeGet("colors.primaryHover", "#3C74FF")};
      }
    }
  }
`;

export default BannerWrapper;
