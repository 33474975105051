import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import ScrollSpyMenu from 'common/src/components/ScrollSpyMenu';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Icon } from 'react-icons-kit';
import { menu } from 'react-icons-kit/feather/menu';
import { x } from 'react-icons-kit/feather/x';
import Logo from 'reusecore/src/elements/UI/Logo';
import Button from 'reusecore/src/elements/Button';
import Container from 'common/src/components/UI/Container';
import NavbarWrapper, { MenuArea, MobileMenu } from './navbar.style';
import { DrawerContext } from 'common/src/contexts/DrawerContext';

const Navbar = () => {
  const data = useStaticQuery(graphql`
    query {
      apploadJson {
        navbar {
          logo {
            publicURL
          }
          navMenu {
            id
            label
            path
            offset
          }
        }
      }
    }
  `);
  const { logo, navMenu } = data.apploadJson.navbar;

  const { state, dispatch } = useContext(DrawerContext);

  const toggleHandler = () => {
    dispatch({
      type: 'TOGGLE',
    });
  };

  return (
    <NavbarWrapper className="navbar">
      <Container>
        <Logo
          className="logo"
          href="/"
          logoSrc={logo.publicURL}
          title="Appload"
        />
        {/* end of logo */}

        <MenuArea>
          <ScrollSpyMenu className="menu" menuItems={navMenu} offset={-84} />
          {/* end of main menu */}

          <Button
            className="menubar"
            icon={
              state.isOpen ? (
                <Icon className="bar" icon={x} />
              ) : (
                <Fade>
                  <Icon className="close" icon={menu} />
                </Fade>
              )
            }
            color="#0F2137"
            variant="textButton"
            onClick={() => toggleHandler()}
          />
        </MenuArea>
        <AnchorLink className="contactContainer" href="#contact" offset={84}>
          <Button className="contact" title="Contact" />
        </AnchorLink>
      </Container>

      {/* start mobile menu */}
      <MobileMenu className={`mobile-menu ${state.isOpen ? 'active' : ''}`}>
        <Container>
          <ScrollSpyMenu
            className="menu"
            drawerClose={state.isOpen}
            menuItems={navMenu}
            offset={-84}
          />
          <AnchorLink href="#contact" offset={84}>
            <Button title="Contact" onClick={() => toggleHandler()} />
          </AnchorLink>
        </Container>
      </MobileMenu>
      {/* end of mobile menu */}
    </NavbarWrapper>
  );
};

export default Navbar;
