import React from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from 'common/src/theme/appload';
import { ResetCSS } from 'common/src/assets/css/style';
import Sticky from 'react-stickynode';
import Navbar from '../containers/Appload/Navbar';
import Banner from '../containers/Appload/Banner';
import KeyFeatures from '../containers/Appload/KeyFeatures';
// import Faq from "../containers/Appload/Faq";
import Portfolio from '../containers/Appload/PortfolioShowcase';
import TestimonialSection from '../containers/Appload/Testimonial';
import Footer from '../containers/Appload/Footer';
import {
  GlobalStyle,
  AppWrapper,
  ContentWrapper,
} from '../containers/Appload/appload.style';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';

import SEO from '../components/seo';
// import Team from '../containers/Appload/Team';

export default function () {
  return (
    <ThemeProvider theme={theme}>
      <>
        <SEO title="Appload" />
        <ResetCSS />
        <GlobalStyle />

        <AppWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <ContentWrapper>
            <Banner />
            <KeyFeatures />
            <Portfolio />
            {/* <Team /> */}
            <TestimonialSection />
            {/* <Faq /> */}
          </ContentWrapper>
          <Footer />
        </AppWrapper>
      </>
    </ThemeProvider>
  );
}
