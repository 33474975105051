import styled from 'styled-components';

const SectionWrapper = styled.section`
  background-color: #f8f8f8;
  padding: 100px 0 0 0;
  overflow: hidden;
  min-height: 100vh;
  display: flex;
  align-items: center;
  @media only screen and (max-width: 1366px) {
    padding: 60px 0;
  }
  @media only screen and (max-width: 667px) {
    padding: 45px 0;
  }
`;

export default SectionWrapper;
