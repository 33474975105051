import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import Box from 'reusecore/src/elements/Box';
import Container from 'common/src/components/UI/Container';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import SliderDes from '../sliderDescription';
import amtIcon from 'common/src/assets/image/appload/testimonials/amt.png';
import newrowIcon from 'common/src/assets/image/appload/testimonials/newrow.png';
import qualegoIcon from 'common/src/assets/image/appload/testimonials/qualego.png';
import bcfIcon from 'common/src/assets/image/appload/testimonials/bcf.png';
import Heading from 'reusecore/src/elements/Heading';
import { SectionHeader } from '../appload.style';
import SectionWrapper from './testimonial.style';

const TestimonialSection = ({ sectionWrapper }) => {
  const Data = useStaticQuery(graphql`
    query {
      apploadJson {
        testimonials {
          id
          name
          designation
          description
        }
      }
    }
  `);

  const images = [
    {
      thumbnail: `${amtIcon}`,
      thumbnailAlt: 'AmT',
      description: <SliderDes data={Data.apploadJson.testimonials[0]} />,
    },
    {
      thumbnail: `${qualegoIcon}`,
      thumbnailAlt: 'QauleGo',
      description: <SliderDes data={Data.apploadJson.testimonials[1]} />,
    },
    {
      thumbnail: `${newrowIcon}`,
      thumbnailAlt: 'newrow_',
      description: <SliderDes data={Data.apploadJson.testimonials[2]} />,
    },
    {
      thumbnail: `${bcfIcon}`,
      thumbnailAlt: 'BCF',
      description: <SliderDes data={Data.apploadJson.testimonials[3]} />,
    },
  ];
  const renderItem = item => {
    return (
      <div className="image-gallery-image">
        {item.description && (
          <span className="image-gallery-description">{item.description}</span>
        )}
      </div>
    );
  };

  return (
    <SectionWrapper id="testimonials">
      <Container>
        <Box {...sectionWrapper} className="testimonialSlider">
          <SectionHeader>
            <Fade up>
              <Heading as="h2" content="Testimonials" />
            </Fade>
          </SectionHeader>
          <Fade up>
            <Box className="testimonialDesWrapper">
              <ImageGallery
                items={images}
                originalClass="Testimonial-img"
                showPlayButton={false}
                showFullscreenButton={false}
                renderItem={renderItem}
              />
            </Box>
          </Fade>
        </Box>
      </Container>
    </SectionWrapper>
  );
};

TestimonialSection.propTypes = {
  sectionWrapper: PropTypes.object,
  title: PropTypes.object,
};

TestimonialSection.defaultProps = {
  sectionWrapper: {
    as: 'section',
    pt: '0px',
    pb: ['20px', '80px', '0px', '80px', '80px'],
  },

  sectionSubTitle: {
    content: 'CLIENT TESTIMONIAL',
    as: 'span',
    display: 'block',
    textAlign: ['center', 'left'],
    fontSize: '14px',
    letterSpacing: '0.11em',
    fontWeight: '700',
    color: '#1a73e8',
    textTransform: 'uppercase',
    mb: '10px',
  },
};

export default TestimonialSection;
