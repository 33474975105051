import React from "react";
import Fade from "react-reveal/Fade";
import Heading from "reusecore/src/elements/Heading";
import BannerWrapper, { BannerContent } from "./banner.style";
import Particles from "../Particle";
import Typer from "common/src/components/Typer";
// import Image from "reusecore/src/elements/Image";
// import { useStaticQuery, graphql } from "gatsby";

const Banner = () => {
  // const data = useStaticQuery(graphql`
  //   query {
  //     apploadJson {
  //       banner {
  //         logo {
  //           publicURL
  //         }
  //       }
  //     }
  //   }
  // `);

  // const { logo } = data.apploadJson.banner;

  return (
    <BannerWrapper id="home">
      <Particles />
      <BannerContent>
        <div>
          <Fade up>
            <Heading fontSize={75} as="h1" fontWeight={700} content="Appload" />
            {/*<Image
              src={logo.publicURL}
              alt={"Appload"}
              height={256}
              m="0 auto"
              className="logo"
            />
            */}
          </Fade>
          <Fade up duration={1500}>
            <Heading
              as="h2"
              fontWeight={500}
              content="Boutique software development & consulting company"></Heading>
          </Fade>
        </div>
        <Typer
          heading={"Specialized in"}
          dataText={[
            "AI - Deep Learning.",
            "Web/Mobile Development.",
            "DevOps."
          ]}
        />
      </BannerContent>
    </BannerWrapper>
  );
};

export default Banner;
